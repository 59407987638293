/* @flow */

import type { ProductCardProduct, GAProduct } from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";

import React, { useRef, useContext, useEffect, useState, useMemo } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { AnalyticsContext, ProductLink } from "@crossroads/analytics";
import { Square, ProductListMedia } from "@crossroads/ui-components";
import useFormat from "helpers/use-format";
import { getLowestMSRP } from "helpers/utils";
import { formatConfigColors } from "components/ProductOptions/color";

import styles from "./styles.scss";

type ProductCardProps = {
  product: ProductCardProduct,
  className?: string,
  list?: string,
  position?: number,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
};

type DiscountTagProps = {
  msrp: ?number,
  price: number,
};

type PriceProps = {
  msrp: ?number,
  price: number,
  showDiscount: ?boolean,
};

const DiscountTag = ({ msrp, price }: DiscountTagProps): React$Node => {
  const t = useTranslate();

  const discount = typeof msrp === "number" ?
    Math.floor((1 - (price / msrp)) * 100) : 0;

  return (
    discount > 0 ?
      <p className={styles.discount}>
        {t("PRODUCT.DISCOUNT_SHORT", { discount })}
      </p> : null
  );
};

const Price = ({ msrp, price, showDiscount }: PriceProps) => {
  const { formatPrice } = useFormat();
  const showMSRP = typeof msrp === "number" && msrp > price && showDiscount === true;

  return (
    <div className={styles.priceContainer}>
      <p className={cn(styles.price, { [styles.showMSRP]: showMSRP })}>
        {formatPrice(price)}
        &nbsp;
        {typeof msrp === "number" && msrp > price && showDiscount === true && (
          <span>{formatPrice(msrp)}</span>
        )}
      </p>
      {showDiscount === true &&
        <DiscountTag price={price} msrp={msrp} showDiscount={showDiscount} />
      }
    </div>
  );
};

const ProductCard = ({
  product,
  className,
  breadcrumbLinks,
  position,
  list,
}: ProductCardProps): React$Node => {
  const gaContext = useContext(AnalyticsContext);
  const gaRef: { current: null | Element } = useRef(null);
  // Default to smallest image
  const [imageSrc, setImageSrc] = useState<string>("");
  const options = product.type === "configurable" ? product.options.items : [];
  const msrp = useMemo(() => getLowestMSRP(product), [product]);
  const price = product.price.incVat;

  const mapGaObject = (product: ProductCardProduct): GAProduct => {
    const gaObject = {
      item_id: product.sku, // eslint-disable-line camelcase
      item_name: product.name, // eslint-disable-line camelcase
      item_brand: product.attributes.manufacturer, // eslint-disable-line camelcase
      price: product.price.incVat,
      index: Number(position) + 1,
      item_list_name: list ?? "", // eslint-disable-line camelcase
    };

    if (product.categories === undefined || product.categories.length === 0) {
      return gaObject;
    }

    product.categories.forEach((c, i) => {
      if (i === 0) {
        gaObject.item_category = c.name; // eslint-disable-line camelcase
      }
      else {
        gaObject[`item_category${i + 1}`] = c.name;
      }
    });

    return gaObject;
  };

  useEffect(() => {
    const gaObject = mapGaObject(product);

    if (!gaRef.current) {
      return;
    }

    gaContext.register(gaRef.current, gaObject);
  }, [gaRef]);

  return (
    <ProductLink
      className={cn(styles.block, className)}
      list={list}
      to={{
        pathname: product.url,
        state: {
          hint: {
            type: "product",
            product,
            image: imageSrc,
          },
          breadcrumbLinks,
          qty: 1,
          list,
          position: Number(position) + 1,
        },
      }}
      product={{
        name: product.name,
        sku: product.sku,
        price: product.price,
        qty: 1,
        attributes: {
          manufacturer: product.attributes.manufacturer,
        },
        categories: product.categories,
      }}
      innerRef={gaRef}
      position={Number(position) + 1}
    >
      <div className={styles.colors}>
        {options
          .filter(x => Boolean(x.product.attrLabels.color))
          .map((x, i) => (
            <div
              key={i}
              style={{ background: `linear-gradient(${formatConfigColors(x.product.attrLabels.color?.title)})` }} />
          ))}
      </div>

      <div className={styles.header}>
        <ProductListMedia
          className={styles.imageWrapper}
          product={product}
          getCurrentImageSrc={x => setImageSrc(x)}
        />
      </div>

      <div className={styles.body}>
        <div>
          <span className={styles.name}>{product.name}</span>
          <p className={styles.brand}>{product.attributes.manufacturer}</p>
        </div>
        <Price price={price} msrp={msrp} showDiscount={product.attributes.showDiscount} />
      </div>

    </ProductLink>
  );
};

export const DummyCard = ({ className }: { className?: string }): React$Node => {
  return (
    <div className={cn(className, styles.block, styles.dummy)}>
      <div className={styles.imageWrapper}>
        <div className={styles.image}>
          <Square />
        </div>
      </div>

      <div className={styles.body}>
        <p className={styles.brand}>&nbsp;</p>
        <span className={styles.name}>&nbsp;</span>

        <div>
          <p className={styles.price}>&nbsp;</p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
